<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="period">Periode</label>
                    <Calendar selectionMode="range"  v-tooltip.top="'Maksimal 30 hari'" :manualInput="false" v-model="period" :maxDate="maxDate" dateFormat="dd/mm/yy" icon="pi pi-calendar" :showIcon="true"/>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Tipe Outlet</label>
                    <Dropdown id="outlet_type_id" dataKey="outlet_type_id" v-model="filters.outlet_type_id" :loading="loadingDropdownOutletType"
                        :options="dataDropdownOutletType"
                        optionLabel="otype_label" optionValue="otype_id" placeholder="Pilih Tipe Outlet"
                        :filter="true" :showClear="false" @filter="searchDropdownOutletType($event, 'filter')"
                     />
                </div>
            </div>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
    </div>
</template>

<script>

import moment from 'moment';

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownOutletType: false,

            // dataDropdown
            dataDropdownOutletType: null,

            // filter
            filters: {
                outlet_type_id: null,
            },
            period: [new Date(), new Date()],
            maxDate: null,
            minDate: null,
        }
    },
    created(){
        this.searchDropdownOutletType('');
    },
    watch: {
        period(){
            //max range 30 days
            this.maxDate =  new Date(this.period[0].getFullYear(), this.period[0].getMonth(), this.period[0].getDate() + 30);
        }
    },
    computed:{
        period_start_label(){ 
            return moment(this.period[0], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        period_end_label(){ 
            return moment(this.period[1], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
    },
    methods: {
        // DROPDOWN
        searchDropdownOutletType(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dprov.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownOutletType = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/outlet-type',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                        "otype_channel": "mti"
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownOutletType = res.data.data;
                            this.loadingDropdownOutletType = false;
                        } else if (purpose == null) {
                            this.dataDropdownOutletType = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        getDataTable(){
            if(
              this.period_start_label !== null && 
              this.period_end_label !== null && 
              this.period_start_label !== 'Invalid date' && 
              this.period_end_label !== 'Invalid date'
            ){  
                this.$emit('submit');
            }else{
                 alert('Pastikan rentang waktu sudah benar atau tidak kosong');
            }
        }
    }
}
</script>

